<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">DASHBOARD</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-2">
            <div class="row">
              <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5 class="card-title-font-bold">Profile Submitted</h5>
                    <p class="font-bold" style="color: red">23</p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5 class="card-title-font-bold">Doc Submitted</h5>
                    <p class="font-bold" style="color: #ffc107">36</p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5 class="card-title-font-bold">Verified</h5>
                    <p class="font-bold" style="color: orange">50</p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5 class="card-title-font-bold">Approved</h5>
                    <p class="font-bold" style="color: green">40</p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5 class="card-title-font-bold">Griviances</h5>
                    <p class="font-bold" style="color: blue">15</p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h8 class="card-mini-title-font-bold">Cases</h8>
                    <h5 class="card-title-font-bold">Month above 18 years</h5>
                    <p class="font-bold" style="color: blue">15</p>
                    <p>
                      <i class="fa fa-refresh" aria-hidden="true"></i> Just
                      Updated
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 row">
          <!-- <div class="col-lg-6 card-body py-0 px-2">
            <div class="card border mb-0 radius-15 mb-1">
                <div class="col-lg-12">
                  <div class="row col-lg-12 col-sm-6 my-auto" style="justify-content: center;">
                    <h5 class="card-mini-title-font-bold m-2">PROFILE SUBMITTED 2024</h5>
                  </div>
                  <canvas
                    id="goodCanvas1"
                    width="10"
                    height="10"
                    aria-label=""
                    role="img"></canvas>
                </div>
            </div>
          </div>
           <div class="col-lg-6 card-body py-0 px-2">
            <div class="card border mb-0 radius-15 mb-1">
                <div class="col-lg-12">
                  <div class="row col-lg-12 col-sm-6 my-auto" style="justify-content: center;">
                    <h5 class="card-mini-title-font-bold m-2">VERIFIED PROFILES 2024</h5>
                  </div>
                  <canvas
                    id="goodCanvas2"
                    width="50"
                    height="50"
                    aria-label=""
                    role="img"></canvas>
                </div>
            </div>
          </div> -->
          </div>
          <div class="card-body py-0 px-2">
            <div class="card border mb-0 radius-15 mt-3">
              <div class="card-body p-0 pb-4">
                <div class="shadow-btm">
                  <div class="row col-lg-6 col-sm-6 my-auto">
                    <h5 class="card-mini-title-font-bold">ALL PERSON LIST</h5>
                  </div>
                  <div class="row col-lg-6 col-sm-6 my-auto">
                    <h3 class="card-title-font-bold">New Demise List</h3>
                  </div>
                </div>
                <div class="table-responsive">
                  <div class="tableFixHead">
                    <table
                      class="table table-striped table-bordered"
                      role="grid"
                      aria-describedby="example_info"
                      id="members-list">
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th
                            id="id-name"
                            v-on:click="sorting('name', 'id-name')">
                            Name
                          </th>
                          <th
                            id="id-mobile"
                            v-on:click="sorting('phone', 'id-mobile')">
                            Deaprtment/Designation
                          </th>
                          <th
                            id="id-mobile"
                            v-on:click="sorting('phone', 'id-mobile')">
                            DODEMISE
                          </th>
                          <th
                            id="id-mobile"
                            v-on:click="sorting('phone', 'id-mobile')">
                            Mobile
                          </th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align: left">
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td style="text-align: left">
                            {{ data.name }}
                          </td>
                          <td style="text-align: left">
                            {{ data.dial_code }} {{ data.phone }}
                          </td>
                          <td style="text-align: left">
                            {{ data.dial_code }} {{ data.phone }}
                          </td>
                          <td style="text-align: left">
                            {{ data.dial_code }} {{ data.phone }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
<script>
// import { Chart, registerables } from "chart.js";
// Chart.register(...registerables);

// const data = {
//   labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
//   datasets: [
//     {
//       label: "My First Dataset",
//       data: [11, 16, 7, 3, 14, 9, 5, 2, 8, 10, 15, 20],
//       backgroundColor: [
//       "#1f77b4",
//       "#ff7f0e",
//       "#2ca02c",
//       "#d62728",
//       "#9467bd",
//       "#8c564b",
//       "#e377c2",
//       "#7f7f7f",
//       "#bcbd22",
//       "#17becf",
//       "#393b79",
//       "#637939"
//       ],
//     },
//   ],
// };
// const config = {
// type: 'bar',
//   data: data,
//   options: {
//     scales: {
//       y: {
//         beginAtZero: true
//       }
//     }
//   },
// };
// const config2 = {
//   type: "doughnut",
//   data: data,
//   options: {},
// };
export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      loader: true,
      searchObj: {
        keyword: "",
      },
      offset: -1,
      list: [],
      user_type: "",
    };
  },

  watch: {
    "$route.fullPath"(newValue) {
      this.pageName = newValue.replace("/", "");
      this.$helperService.showMenu("true");
      this.getList(0);
    },
  },
  mounted() {
    this.$helperService.showMenu("true");

    // const ctx = document.getElementById("goodCanvas1").getContext("2d");
    // new Chart(ctx, config);
    // const ctx2 = document.getElementById("goodCanvas2").getContext("2d");
    // new Chart(ctx2, config2);
    // this.getList(this.offset - 1);
  },
  methods: {
    itemsPerPageChange() {
      // this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      // this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.searchObj.role = this.user_type;
      this.$api
        .getAPI({
          _action: "/user-pagination-list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject =
            this.$helperService.getAccessLevel("Manage staff");
          if (page == 0) {
            // if (this.$refs.listPagination) {
            //   if (res.count != null && res.count > 0) {
            //     this.$refs.listPagination.setTotalCount(res.count);
            //   } else {
            //     this.$refs.listPagination.setTotalCount(0);
            //   }
            // }
          }
          // if (this.$refs.errorComponent) {
          //   this.$refs.errorComponent.setData(res, "No Admin List Available");
          //   this.$refs.errorComponent.updateListLoader(false);
          // }
        })
        .catch(() => {
          this.loader = false;
          // if (this.$refs.errorComponent) {
          //   this.$refs.errorComponent.setData(0, undefined, "");
          //   this.$refs.errorComponent.updateListLoader(false);
          // }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Admin",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/user/" + detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: "Admin deleted successfully",
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>
