<template>
  <div class="wrapper">
    <div><Menu /></div>
    <div class="content-page">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/menu";
export default {
  name: "Main",
  components: {
    Menu,
  },
  methods: {
    callMethod() {
      Menu.methods.getLoginUserDetail();
    },
  },
};
</script>
<style>
   .content-page{
    margin-top: 75px;
   }
</style>
