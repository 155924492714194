import { createWebHistory, createRouter } from "vue-router";
import Login from "@/views/Onboarding/login.vue";
import ForgotPassword from "@/views/Onboarding/forgot-password.vue";
import OTP from "@/views/Onboarding/otp.vue";
import ResetPassword from "@/views/Onboarding/reset-password.vue";
import MyProfile from "@/views/Onboarding/my-profile.vue";
import Admin from "@/views/Admin/admin.vue";
import AddAdmin from "@/views/Admin/add-admin.vue";
import Grievance from "@/views/Grevience/grevience.vue";
import Feedback from "@/views/FeedBacks/feedback.vue";
import FeedbackComments from "@/views/FeedBacks/feedback-comment.vue";
import FeedbackWithoutAuthComments from "@/views/FeedBacks/feedback-comment-without-auth.vue";
import ViewGrevience from "@/views/Grevience/view-grevience.vue";
import ViewSettlement from "@/views/Grevience/view-grevience-settlement.vue";
import ViewAppointment from "@/views/Grevience/view-grevience-appointment.vue";
import MainView from "@/views/main.vue";
import AccessDenied from "@/views/Error/access-denied.vue"
import Dashboard from "@/views/Dashboard/dashboard.vue";

const routes = [
    {
        path: "",
        redirect: Login,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/otp",
        name: "OTP",
        component: OTP,
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "/comment/:id",
        name: "View FeedbackWithoutAuthComments",
        component: FeedbackWithoutAuthComments
    },
    {
        path: '',
        name: "Main",
        component: MainView,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                component: Dashboard,
            },
            {
                path: "/access-denied",
                name: "AccessDenied",
                component: AccessDenied,
            },
            // =============== Admin ===============
            {
                path: "/appointment-offcial",
                name: "appointment-offcial",
                component: Admin
            },
            {
                path: "/settlement-offcial",
                name: "settlement-offcial",
                component: Admin
            },
            {
                path: "/field-inspector-offcial",
                name: "field-inspector-offcial",
                component: Admin
            },
            {
                path: "/add-appointment-offcial",
                name: "Add appointment offcial",
                component: AddAdmin
            },
            {
                path: "/add-settlement-offcial",
                name: "Add settlement offcial",
                component: AddAdmin
            },
            {
                path: "/add-field-inspector-offcial",
                name: "Add field inspector offcial",
                component: AddAdmin
            },

            {
                path: "/edit-admin/:id",
                name: "Edit Admin",
                component: AddAdmin
            },



            {
                path: "/manage-grievance",
                name: "Manage grievance",
                component: Grievance
            },
            {
                path: "/manage-appointment",
                name: "Mange appointment",
                component: Grievance
            },
            {
                path: "/manage-settlement",
                name: "Manage settlement",
                component: Grievance
            },
            {
                path: "/manage-feedback",
                name: "Manage feedback",
                component: Feedback
            },
            {
                path: "/view-comments/:id",
                name: "View FeedbackComments",
                component: FeedbackComments
            },
            {
                path: "/add-grevience",
                name: "Add grevience",
                component: ViewGrevience
            },
            {
                path: "/view-grevience/:id",
                name: "View grevience",
                component: ViewGrevience
            },

            {
                path: "/view-appointment/:id",
                name: "View appointment",
                component: ViewAppointment
            },

            {
                path: "/view-settlement/:id",
                name: "View Settlement",
                component: ViewSettlement
            },

            {
                path: "/my-profile",
                name: "MyProfile",
                component: MyProfile,
            },
        ]
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        if (to.path === '/login') {
            if (localStorage.getItem('token') != null) {
                next({
                    path: '/manage-grievance',
                    params: { nextUrl: to.fullPath }
                })
            } else {
                next()
            }
        } else {
            next()
        }
    }
});

export default router;